import { Box, TextField, Grid, Button, Card,CardContent,CardHeader,CardActions} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useContext} from "react";
import axios from 'axios';
import SnackBar from './../contexts/SnackBar';
import User from './../contexts/User';

const Login = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { setSnackbar } = useContext(SnackBar);
    const { setUser } = useContext(User);


    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };
  
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
  
    const login =  async () => {
    
        const response = await axios.post(`/api/auth/login`,{
            username: username,
            password: password
        });

        if(response.data.status === "success"){
            setSnackbar({message: response.data.message, type: response.data.status});
            setUser(response.data.data)
            navigate("/");
        }
        else{
            if(response.data.message){
                setSnackbar({message: response.data.message, type: response.data.status});
            }
            else{
                setSnackbar({message:"Failed to login",type:"error"})
            }

        }
    }

    return (
        <Grid sx={{ height: "70vh", p: 3 }} container direction="column" justifyContent="flex-end" alignItems="center">
            <Grid item xs={4} >

            <Card sx={{"padding":1}}variant="outlined">
                    <CardHeader subheaderTypographyProps={{ color: '#bfbfbf' }} titleTypographyProps={{variant:'h4'}} sx={{"textAlign":"center"}} title="Login" subheader="Control and configure PTZ cameras from anywhere"/>
                    <CardContent>
                        <Box sx={{"paddingTop":1,"paddingBottom":1}} >
                            <TextField fullWidth label="Username" type="text" onChange={handleUsernameChange} value={username}  variant="outlined" />
                        </Box>
                        <Box sx={{"paddingTop":1,"paddingBottom":1}} >
                            <TextField fullWidth label="Password" type="password" onChange={handlePasswordChange} value={password} variant="outlined" />
                        </Box>
                    </CardContent>
                    <CardActions
                    sx={{
                        alignSelf: "stretch",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                     
                      }}
                  >
                        <Button onClick={login} size="small">Login</Button>
                    </CardActions>
            </Card>
            
        
            </Grid>
        </Grid>
    );
};

export default Login;
