import { BrowserRouter } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppBar from "./components/AppBar";
import Router from "./components/Router";

import SnackBar from "./providers/SnackBar";
import User from "./providers/User";

const theme = createTheme({
    palette: {
        type: "dark",
        text: {
            primary: "#ededed",
            secondary: "#263238",
        },   
        primary: {
            main: "#b53f41",
        },
        secondary: {
            main: "#1db17b",
        },
        background: {
            default: "#303030",
            paper: "#424242",
        }
    }
});

const App = () => {

    return (
        <>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <User>
                        <SnackBar>
                            <AppBar>
                                <ReactFlowProvider>
                                    <Router/>
                                </ReactFlowProvider>
                            </AppBar>
                        </SnackBar>
                    </User>
                </BrowserRouter>
            </ThemeProvider>
        </>
    );
};

export default App;
