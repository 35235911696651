import React, { useCallback, useRef, useEffect} from "react";
import ReactFlow, { ReactFlowProvider, useNodesState, useEdgesState, Controls, updateEdge, addEdge, useReactFlow } from "reactflow";
import GpioNode from "./../components/GpioNode";
import socket from './../utils/socket';
import "reactflow/dist/style.css";

const nodeTypes = { gpioNode: GpioNode };

const Home = () => {
    const reactFlowInstance = useReactFlow();
    const edgeUpdateSuccessful = useRef(true);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const updateNodePosition = (nodeId, position) =>{
        socket.send(JSON.stringify({action:"set-pos",nodeId:nodeId, data:position}));
    }

    //On component mount get links and nodes information
    useEffect(() => {
        if(socket.readyState === socket.OPEN){
            socket.send(JSON.stringify({action:"get",data:"links"}));
            socket.send(JSON.stringify({action:"get",data:"nodes"}));
        }
    },[]);
      
    const updateLinks = () =>{
        const links = {};
        const currentEdges = reactFlowInstance.getEdges()

        for(let edge of currentEdges){
            if(!links[edge.source]){
                links[edge.source] = {}
            }
            
            if(!links[edge.source][edge.sourceHandle]){
                links[edge.source][edge.sourceHandle] = []
            }
            links[edge.source][edge.sourceHandle].push(`${edge.target}:${edge.targetHandle}`)
        }
        socket.send(JSON.stringify({action:"set-links", data:links}));
    }

    socket.onmessage = (message) => {
        if (typeof message.data === 'string') {
            const data = JSON.parse(message.data);
            const nodeItems = [];
            const linkItems = [];

            switch(data.action) {                
                case "get":
                    if(data?.data["nodes"]){   
                        for(let nodeId in data?.data["nodes"] ){
                            nodeItems.push({id:nodeId, position: data?.data.nodes[nodeId].position, data:data?.data?.nodes[nodeId], type:"gpioNode",})
                        }
                        setNodes(nodeItems)
                    }

                    if(data?.data["links"]){   
                        for(let sourceNodeId in data?.data["links"] ){
                            for(let sourceId in data?.data["links"][sourceNodeId] ){
                                for(let link of data?.data["links"][sourceNodeId][sourceId] ){
                                    const linkSplit = link.split(":")
                                    const edgeId = `${sourceNodeId}-${sourceId}:${link}`;

                                    const linkData = { id: edgeId, key: edgeId, source: sourceNodeId, sourceHandle: sourceId, target: linkSplit[0], targetHandle: linkSplit[1] };


                                    if(data?.data?.nodes[sourceNodeId]?.online && data?.data?.nodes[linkSplit[0]]?.online){
                                        linkData.animated = true;
                                        linkData.label = `${(data?.data?.nodes[sourceNodeId]?.rtt+data?.data?.nodes[linkSplit[0]]?.rtt)/2}ms`
                                    }

                                    linkItems.push(linkData);

                                }
                            }                     
                           }
                           setEdges(linkItems);

                    }  
            }
        }
    };

    const onEdgeUpdateStart = useCallback(() => {
      edgeUpdateSuccessful.current = false;
    }, []);
  
    const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
      edgeUpdateSuccessful.current = true;
      setEdges((els) => updateEdge(oldEdge, newConnection, els));
    }, []);
  
    const onEdgeUpdateEnd = useCallback((_, edge) => {
      if (!edgeUpdateSuccessful.current) {
        setEdges((eds) => eds.filter((e) => e.id !== edge.id));
      }  
      edgeUpdateSuccessful.current = true;
    }, []);

  
    const onConnect = useCallback((params) => setEdges((els) => addEdge(params, els)), []);
    
    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeDragStop={(event, node) => {
                updateNodePosition(node?.id, node?.position);
            }}
            nodeTypes={nodeTypes}
            onEdgeUpdate={onEdgeUpdate}
            onEdgeUpdateStart={onEdgeUpdateStart}
            onEdgeUpdateEnd={onEdgeUpdateEnd} 
            onEdgeMouseLeave={updateLinks}     
            onConnect={onConnect}
            snapToGrid
            fitView
            attributionPosition="bottom-right"
        >
            <Controls />
        </ReactFlow>
    );
};

export default Home;
