import { useState } from "react";
import { Handle, Position } from "reactflow";
import { Box, Typography } from "@mui/material";
import DestinationSettings from "./DestinationSettings";
import socket from './../utils/socket';

const getStatusText = (status) => {
    if(status){
        return "Online";
    }
    return "Offline";
}

const getRttText = (data) => {
    if(data.online){
        return (<Typography variant="subtitle2" style={{fontSize: "0.58em"}}>{data.rtt}ms</Typography>)
    }
}

const getColor = (status) =>{
    if(status){
        return "rgba(29, 177, 123, 0.75)"
    }
    return "rgba(91, 91, 91, 0.75)"
}


const GpioNode = ({ data, isConnectable }) => {
    const destinations = data.destinations?.length || 0;
    const sources = data.sources.length || 0;
    const name = data.name || data?.nodeId || "GPIO Box";

    const [destinationSettingsOpen, setDestinationSettingsOpen] = useState(false);
    const [selectedDestination, setSelectedDestination] = useState();

    const openDestinationSettings = (destination) => {
            setDestinationSettingsOpen(true);
            setSelectedDestination(destination);
    }

    const destinationSettingsChange = (destinationId,destinationSettings) =>{
        setDestinationSettingsOpen(false);
        socket.send(JSON.stringify({action:"update-destination", nodeId: data?.nodeId, destinationId: destinationId, data:destinationSettings}));
    }
    
    const getDestinations = () => {
        const handles = [];

        for (let i = 0; i < destinations; i++) {
            handles.push(
                <Box key={i}>
                    <Handle
                        type="target"
                        position={Position.Left}
                        id={data.destinations[i]?.destinationId}
                        key={data.destinations[i]?.destinationId}
                        style={{ top: `${i+1 * 1}em` }}
                        isConnectable={isConnectable}
                    />

                    <Typography
                        variant="subtitle1"
                        style={{
                            position: "absolute",
                            left: "1em",
                            top: `${0.8 + i * 1.73}em`,
                            fontSize: "0.58em",
                        }}
                        onClick={()=>openDestinationSettings(data.destinations[i])}
                    >
                        {data.destinations[i]?.destinationId}
                    </Typography>
                </Box>
            );
        }
        return handles;
    };

    const getSources = () => {
        const handles = [];

        for (let i = 0; i < sources; i++) {
            handles.push(
                <Box key={i} style={{ top: 0 }}>
                    <Handle
                        type="source"
                        position={Position.Right}
                        id={data.sources[i].sourceId}
                        key={data.sources[i].sourceId}
                        style={{ top: `${i+1 * 1}em` }}
                        isConnectable={isConnectable}
                    />
                    <Typography
                        variant="subtitle1"
                        style={{
                            position: "absolute",
                            right: "1em",
                            top: `${0.8 + i * 1.73}em`,
                            fontSize: "0.58em",
                        }}
                    >
                        {data.sources[i].sourceId}
                    </Typography>
                </Box>
            );
        }
        return handles;
    };

    return (
        <Box
            style={{
                height: `${sources > destinations ? sources + 1 : destinations + 2}em`,
                minHeight: "6em",
                width: "12em",
                border: "0.5px solid #424242",
                padding: "5px",
                borderRadius: "3px",
                background: getColor(data.online),
            }}
        >
            <Box>{getSources()}</Box>
            <Box>{getDestinations()}</Box>

            <Box style={{ paddingLeft: "3em", paddingRight: "3em" }}>
                <Typography variant="subtitle2">{name}</Typography>
                <Typography variant="subtitle2" style={{fontSize: "0.58em"}} >ID: {data?.nodeId}</Typography>
                <Typography variant="subtitle2" style={{fontSize: "0.58em"}}>{getStatusText(data.online)}</Typography>
                {getRttText(data)}
                <Typography variant="subtitle2" style={{fontSize: "0.58em"}}>{data?.network?.ipv4}</Typography>

            </Box>
            <DestinationSettings
                selectedDestination={selectedDestination}
                open={destinationSettingsOpen}
                onClose={destinationSettingsChange}
            />
        </Box>
    );
};

export default GpioNode;
