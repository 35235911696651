import UserContext from "./../contexts/User";
import Loading from "./../components/Loading";

import {useState,useEffect} from "react";
import axios from 'axios';

const User = ({children}) => {

    const [user,setUser] = useState();
    const [loading,setLoading] = useState(true);


    const getUser = async () => {
        const response = await axios.get(`/api/users/current`);
        setUser(response.data.data)
        setLoading(false);
    }

    const getChildren = () => {
        if(loading){
            return(<Loading/>)
        }
        return children
    }

    useEffect(() => {
        getUser();
    },[]);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {getChildren()}
        </UserContext.Provider>
    );
};

export default User;