import WebSocketClient from 'websocket';

const socket = new WebSocketClient.w3cwebsocket(`wss://${window.location.hostname}/socket`);

socket.onerror = function() {
    console.log('Connection Error');

};

socket.onopen = function() {
    console.log('WebSocket socket connected');

    const  get = (data) => {
        if (socket.readyState === socket.OPEN) {
            socket.send(JSON.stringify({action:"get",data:data}));
        }
    }
    get("nodes");
    get("links");

};

socket.onclose = () => {
    console.log('echo-protocol socket closed');
};

export default socket;
