import SnackBarContext from "./../contexts/SnackBar";
import SnackBar from "./../components/SnackBar";

import {useState} from "react";

const SnackBarProvider = ({children}) => {

    const [snackbar,setSnackbar] = useState({});

    return (
        <SnackBarContext.Provider value={{ snackbar, setSnackbar }}>
            {children}
            <SnackBar {...snackbar} /> 
        </SnackBarContext.Provider>
    );
};

export default SnackBarProvider;