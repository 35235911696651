import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Box, Typography } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const DestinationSettings = ({onClose, selectedDestination, open}) => {

  const handleClose = () => {
    onClose(selectedDestination?.destinationId,{settings:{xInvert:false,yIntert:false}});
  };

  const getSettings = () => {
    if(selectedDestination?.type === "motor"){
          return(
        <FormGroup>
          <FormControlLabel control={<Switch defaultChecked={selectedDestination?.settings?.xInvert} />} label="Invert Pan" />
          <FormControlLabel control={<Switch defaultChecked={selectedDestination?.settings?.yInvert} />} label="Invert Tilt" />
        </FormGroup>
      );
    }
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{`${selectedDestination?.destinationId} Settings`}</DialogTitle>
      <Box style={{ paddingLeft: "1em",paddingRight: "1em", paddingBottom:"1em" }}>
        {getSettings()}
      </Box>
    </Dialog>
  );
}

export default DestinationSettings;
