import {useState,useEffect} from "react";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const SnackBar = ({message,type="success",duration=5000}) => {

    const [open,setOpen] = useState(false);

    useEffect(() => {
        if(message){
            setOpen(true);
        }
    },[message]);

    const handleClose = () => {
        setOpen(false)
    }

    return (
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} autoHideDuration={duration} onClose={handleClose}>
                <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                    {message}            
                </Alert>
            </Snackbar>
    );
};




export default SnackBar;
