import { Routes, Route } from "react-router-dom";
import { useContext} from "react";
import Error from "./../routes/Error";
import Home from "./../routes/Home";
import Login from "./../routes/Login";
import User from './../contexts/User';

const Router = () => {

    const { user } = useContext(User);

    const getRoutes = () =>{
        let routes = [];
        let publicRoutes = [<Route key="/login" path="/login" element={<Login/>}/>];
        let userRoutes = [<Route key="/" path="/" element={<Home/>}/>];
        let adminRoutes = [];

        //If no user, redirect to login
        if(!user){
            routes = routes.concat([<Route key="default" index element={<Login />} />]);
        }

        // Add Public Routes
        routes = routes.concat(publicRoutes);

        //Add User Routes
        if(user && user.roles.includes("user")){
            routes = routes.concat(userRoutes);
        }

        //Add Admin Routes
        if(user && user.roles.includes("admin")){
            routes = routes.concat(adminRoutes);
        }

        return routes;
    }

    return (
        <Routes errorElement={<Error />}>
            {getRoutes()}
        </Routes>
    );
};

export default Router;
