import { Box, Avatar, Grid, Menu, MenuItem, ListItemText, ListItemIcon} from "@mui/material";
import {Logout} from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { useContext, useState} from "react";
import User from './../contexts/User';
import SnackBar from './../contexts/SnackBar';
import axios from 'axios';
import md5 from 'md5';
    
const UserBar = () => {
    const navigate = useNavigate();
    const { user, setUser } = useContext(User);
    const { setSnackbar } = useContext(SnackBar);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const logout = async () => {
      const response = await axios.post(`/api/auth/logout`);
      setSnackbar({message: response?.data?.message, type: response?.data?.status});
      if(response?.data?.status){
          setUser();
          navigate("/login");
      }
    }

    const login = () => {
        navigate("/login")
    }

    const getInitials = (name = "?") => {
        let initials = "";

        const names = name.split(" ");
        for(let item of names){
            initials += item[0]
        }
        return initials;
    }

    const getGravatar = (email) => {
        let url = '';

        if(email){
            url = `https://www.gravatar.com/avatar/${md5(email)}?d=404`
        }

        return url;
    }

    const getUserBar = () => {
      if(user){
        return(
          <>
            <Box onClick={handleClick}>
              <Grid container spacing={1} direction="row" justify="flex-end" alignItems="center">
                  <Grid item>
                      {user?.name}  
                  </Grid>
                  <Grid item>
                      <Avatar sx={{ width: 38, height: 38 }} src={getGravatar(user?.email)}>{getInitials(user?.name)}</Avatar>
                  </Grid>
              </Grid>
            </Box>   
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            </Menu>
          </>
        );
      }
    }

    return getUserBar();
};

export default UserBar;
